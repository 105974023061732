import React from 'react';
import { useIntl } from 'react-intl';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { Container, Grid, Typography } from '@mui/material';
import MainLayout from 'layouts/MainLayout';
import { AnimatedContainer } from 'components/containers';
import { useLocalization } from 'gatsby-theme-i18n';
import { useOfferItems } from 'hooks';
import img from 'images/not-found.png';

const NotFoundPage = () => {
  const { locale, defaultLang } = useLocalization();
  const intl = useIntl();
  const items = useOfferItems();
  return (
    <MainLayout pageTitle="Page Not Found | NextGen" offerItems={items} darkNavbar>
      <AnimatedContainer delay={100}>
        <Container maxWidth="md" sx={{ pt: { xs: 4, md: 12 }, pb: 7, minHeight: 'calc(100vh - 78px - 228px)' }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 4, md: 8 }}
          >
            <Grid item xs={12} md={8} container alignItems={{ xs: 'center', md: 'start' }} flexDirection="column">
              <Typography variant="h1" mb={3}>
                {intl.formatMessage({ id: 'Page not found' })}
              </Typography>
              <Typography variant="h4" component="h2" mb={3} textAlign={{ xs: 'center', md: 'left' }}>
                {intl.formatMessage({ id: 'Sorry' })}
                <span role="img" aria-label="Pensive emoji">
                  😔
                </span>{' '}
                {intl.formatMessage({ id: "we couldn't find what you were looking for" })}
              </Typography>
              <Typography
                color="primary"
                variant="h6"
                component={AniLink}
                fade
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'common.white',
                  width: 'fit-content',
                  '&:hover': {
                    borderColor: 'primary.main'
                  }
                }}
                to={locale === defaultLang ? '/' : `/${locale}`}
              >
                {intl.formatMessage({ id: 'go-home-btn' })}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{
                '& img': {
                  width: '100%',
                  height: 'auto'
                }
              }}
            >
              <img src={img} alt="Not Found" width="620" />
            </Grid>
          </Grid>
        </Container>
      </AnimatedContainer>
    </MainLayout>
  );
};

export default NotFoundPage;
